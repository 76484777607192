import request from '@/utils/request';
import type { APIResponseType, APIResponseEmptyData } from 'requestApi';

// 定义频率类型的枚举
export enum FrequencyType {
  FREQUENCY_TYPE_UNSPECIFIED = 0, // 未指定
  FREQUENCY_TYPE_DAY_OF_MONTH = 1, // 每月某一天 MONTHLY + BY_DATE
  FREQUENCY_TYPE_WEEK_OF_MONTH = 2, // 每月第几周 MONTHLY + BY_DAY
  FREQUENCY_TYPE_DAY_OF_WEEK = 3, // 每周某一天
  FREQUENCY_TYPE_DAILY = 4, // 每天
}
// 对应枚举 AbcFrequencyWeek
export enum Weekday {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum WeekFrequency {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  LAST = 5,
}

export type GetABCLevelConfigData = {
  calculate_frequency: {
    frequency_type?: FrequencyType;
    monthday?: number; // 1到28
    ordinal_weekday?: {
      ordinal: WeekFrequency;
      weekday: Weekday;
    };
    weekday: Weekday;
  };
  exclusion_dates?: {
    start_date: string;
    end_date: string;
    reason: string;
  }[];
  last_refresh_time?: number;
  next_refresh_time?: number;
} & Record<string, any>;

export const getABCLevelConfig = (): Promise<
  APIResponseType<GetABCLevelConfigData>
> => {
  /* PermissionKey: PC.RuleCenter.ABCSetting */
  return request.get('/api/v2/apps/basic/abc/search_new_abc_config');
};

export type UpdateABCLevelConfigRequest = {
  calculate_frequency: {
    frequency_type: FrequencyType;
    monthday: number; // 1到28
    ordinal_weekday: {
      ordinal: WeekFrequency;
      weekday: Weekday;
    };
    weekday: Weekday;
  };
  exclusion_dates: {
    start_date: string;
    end_date: string;
    reason: string;
  }[];
} & Record<string, any>;

export const updateABCLevelConfig = (
  params: UpdateABCLevelConfigRequest
): Promise<APIResponseType<APIResponseEmptyData>> => {
  /* PermissionKey: PC.RuleCenter.ABCSetting */
  return request.post('/api/v2/apps/basic/abc/update_new_abc_config', {
    ...params,
  });
};

export const searchABCConfigModifyLog = (params: any = {}) => {
  /* PermissionKey: PC.RuleCenter.ABCSetting */
  return request.get('/api/v2/apps/basic/abc/search_abc_config_modify_log', {
    ...params,
  });
};

export const searchABCConfig = () => {
  /* PermissionKey: ALL */
  return request.get('/api/v2/apps/basic/location/search_abc_config');
};

export const getCategoryL1ByPrefix = (keyword: string) => {
  /* PermissionKey: PC.RuleCenter.ABCSetting */
  return request.get('/api/v2/apps/basic/abc/get_category_l1_by_prefix', {
    params: {
      prefix: keyword,
    },
  });
};

export const runCalculateABC = async (): Promise<
  APIResponseType<APIResponseEmptyData>
> => {
  /* PermissionKey: PC.RuleCenter.ABCSetting */
  const res = request.post('/api/v2/apps/basic/abc/run_calculate_abc');

  return res;
};

export interface SearchRunHistoryRequest {
  pageno: number;
  count: number;
}

interface ExclusionDate {
  start_date: string; // 开始日期，格式为 "YYYY/MM/DD"
  end_date: string; // 结束日期，格式为 "YYYY/MM/DD"
  reason: string; // 原因说明
}

interface ABCRunLogItem {
  start_time: string;
  end_time: string;
  calculation_mode: string;
  trigger_mode: string;
  exclusion_date: ExclusionDate[];
  sku_qty_class_changed: number;
  operator: string;
}

interface SearchRunHistoryResponse {
  list: ABCRunLogItem[];
  total: number;
  pageno: number;
  count: number;
}

export const searchRunHistory = async (
  params: SearchRunHistoryRequest
): Promise<APIResponseType<SearchRunHistoryResponse>> => {
  const res = request.get(
    /* PermissionKey: PC.RuleCenter.ABCSetting */
    '/api/v2/apps/basic/abc/search_abc_run_calculate_history',
    {
      params: {
        ...params,
      },
    }
  );

  return res;
};
