import { lazy } from 'react';
import Layout from '../../views/layout/index.vue';
import reactContainerFactory from '@/components/react-container/reactContainerFactory';

const exceptionCenter = {
  path: '/exceptionCenter',
  name: 'exceptionCenter',
  meta: {
    title: 'Exception Center',
    icon: 'Exception Center',
  },
  redirect: '/troubleshoottask',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: '/troubleshoottask',
      name: 'exceptionCenter.ibTroubleshootTask',
      meta: {
        title: 'IB Troubleshoot Task',
        filterSave: true,
      },
      component: () =>
        import(
          '../../views/exception-center/troubleshoot-task/troubleshoot-task.vue'
        ),
    },
    {
      path: '/troubleshoottaskdetail',
      name: 'exceptionCenter.ibTroubleshootTaskdetail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'exceptionCenter',
            title: 'Exception Center',
          },
          {
            routeName: 'exceptionCenter.ibTroubleshootTask',
            title: 'IB Troubleshoot',
          },
        ],
      },
      component: () =>
        import(
          '../../views/exception-center/troubleshoot-task/troubleshoot-task-detail.vue'
        ),
    },
    {
      path: '/troubleshoottaskhandle',
      name: 'exceptionCenter.ibTroubleshootTaskhandle',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'exceptionCenter',
            title: 'Exception Center',
          },
          {
            routeName: 'exceptionCenter.ibTroubleshootTask',
            title: 'IB Troubleshoot',
          },
        ],
      },
      component: () =>
        import(
          '../../views/exception-center/troubleshoot-task/troubleshoot-task-detail.vue'
        ),
    },
    {
      path: '/ob-troubleshoot-task',
      name: 'exceptionCenter.obTroubleshootTask',
      meta: {
        title: 'OB Troubleshoot Task',
        filterSave: true,
      },
      component: () =>
        import(
          '../../views/exception-center/ob-troubleshoot-task/ob-troubleshoot-task.vue'
        ),
    },
    {
      path: '/ob-troubleshoot-task-detail/:task_id',
      name: 'exceptionCenter.obTroubleshootTask.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'exceptionCenter',
            title: 'Exception Center',
          },
          {
            routeName: 'exceptionCenter.obTroubleshootTask',
            title: 'OB Troubleshoot Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/exception-center/ob-troubleshoot-task/ob-troubleshoot-task-detail.vue'
        ),
    },
    {
      path: 'rttroubleshoot',
      name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask',
      meta: {
        title: 'RT Troubleshoot Task',
        filterSave: true,
      },
      component: () =>
        import(
          '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task'
        ),
      children: [
        {
          path: 'detail/:exception_id',
          name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.detail',
          meta: {
            title: 'Handling Detail',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import(
              '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/detail'
            ),
        },
        {
          path: 'progress/:exception_id',
          name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.progress',
          meta: {
            title: 'Progress',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import(
              '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/progress'
            ),
        },
        {
          path: 'progress/:exception_id/:exception_sku_id',
          name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.progress.handlingDetail',
          meta: {
            title: 'Handling Detail',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import(
              '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/handling-detail'
            ),
          children: [
            {
              path: 'detail/:exception_id',
              name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.detail',
              meta: {
                title: 'Detail',
                menuHide: true,
              },
              exact: true,
              component: () =>
                import(
                  '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/detail'
                ),
            },
            {
              path: 'progress/:exception_id',
              name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.progress',
              meta: {
                title: 'Progress',
                menuHide: true,
              },
              exact: true,
              component: () =>
                import(
                  '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/progress'
                ),
            },
            {
              path: 'progress/:exception_id/:exception_sku_id',
              name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.progress.handlingDetail',
              meta: {
                title: 'Handling Detail',
                menuHide: true,
              },
              exact: true,
              component: () =>
                import(
                  '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/handling-detail'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/jit-troubleshoot-task',
      name: 'exceptionCenter.jittroubleshoottask',
      meta: {
        title: 'JIT Troubleshoot Task',
        filterSave: true,
      },
      component: reactContainerFactory({
        name: 'exceptionCenter.jitTroubleshootTask',
      }),
      props: {
        component: () =>
          lazy(
            () =>
              import(
                '../../react-app/pages/exception-center/jit-troubleshoot-task/index'
              )
          ),
      },
    },
    // {
    //   path: 'ivtroubleshoot',
    //   name: 'exceptionCenter.ivTroubleshootTask',
    //   meta: {
    //     title: 'IV Troubleshoot Task',
    //   },
    //   component: () => import('@/views/layout/subPage.vue'),
    //   children: [
    //     {
    //       path: 'rttroubleshoot',
    //       name: 'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask',
    //       meta: {
    //         title: 'RT Troubleshoot Task',
    //       },
    //       component: () =>
    //         import(
    //           '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task'
    //         ),
    //       children: [
    //         {
    //           path: 'detail/:exception_id',
    //           name:
    //             'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.detail',
    //           meta: {
    //             title: 'Detail',
    //             menuHide: true,
    //           },
    //           exact: true,
    //           component: () =>
    //             import(
    //               '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/detail'
    //             ),
    //         },
    //         {
    //           path: 'progress/:exception_id',
    //           name:
    //             'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.progress',
    //           meta: {
    //             title: 'Progress',
    //             menuHide: true,
    //           },
    //           exact: true,
    //           component: () =>
    //             import(
    //               '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/progress'
    //             ),
    //         },
    //         {
    //           path: 'progress/:exception_id/:exception_sku_id',
    //           name:
    //             'exceptionCenter.ivTroubleshootTask.rtTroubleshootTask.progress.handlingDetail',
    //           meta: {
    //             title: 'Handling Detail',
    //             menuHide: true,
    //           },
    //           exact: true,
    //           component: () =>
    //             import(
    //               '@/views/exception-center/iv-troubleshoot-task/rt-troubleshoot-task/handling-detail'
    //             ),
    //         },
    //       ],
    //     },
    //   ],
    // },
  ],
};

export default exceptionCenter;
